interface IPhoneData {
    code: number;
    number: string;
}

export function getPhoneString(phoneData: IPhoneData) {
    return `${phoneData.code} ${phoneData.number}`;
}
interface IPhoneData {
    code: number;
    number: string;
}

export function getPhoneString(phoneData: IPhoneData) {
    return `${phoneData.code} ${phoneData.number}`;
}
