import React, {CSSProperties, FC, KeyboardEvent, MouseEventHandler, ReactNode, useCallback, useEffect, useRef, useState} from "react";
import classNames from "classnames";

import {useIsMobile, useIsMounted, useStateRef} from "@pg-mono/hooks";

import {GestureDirection, useSliderTouchEvents} from "../hooks/use_slider_touch_events";
import {getClosestBreakpoint} from "../utils/get_closest_breakpoint";

import {galleryHolder, singleSlide, slider, sliderInnerWrap} from "./SliderGallery.module.css";

export type ISliderTransition = "ease" | "linear" | "ease-in" | "ease-out" | "ease-in-out";
export type IBreakpointSettings = {breakpoint: number; slidesToShow: number; slidesToScroll: number};

interface IProps {
    slides: ReactNode[];
    arrows?: {
        left: JSX.Element;
        right: JSX.Element;
    };
    preventLazyLoad?: boolean;
    onSlideChange?: (currentSlideIndex: number, nextSlideIndex: number) => void;
    sliderTransition?: ISliderTransition;
    clonedSlidesCount?: number;
    forceInitialize?: true;
    onResizeDelay?: number; // miliseconds
    initialSlide?: number;
    disableKeyboardEvents?: boolean;
    className?: string;
    slideStyles?: CSSProperties | CSSProperties[];

    /// slick-slider-like options:
    slidesToShow?: number;
    slidesToScroll?: number;
    speed?: number;
    breakpoints?: IBreakpointSettings[];
}

const DEFAULT_SPEED = 300;
const DEFAULT_SLIDER_TRANSITION = "ease-out";
const DEFAULT_CLONED_SLIDES_COUNT = 2;
const DEFAULT_SLIDES_TO_SHOW = 1;
const DEFAULT_SLIDES_TO_SCROLL = 1;

// I am using a lot of refs here to avoid potential issues with stale state being used inside event listeners. Reference to equal state value will always be up to date.

export const SliderGallery: FC<IProps> = (props) => {
    const {
        sliderTransition = DEFAULT_SLIDER_TRANSITION,
        speed = DEFAULT_SPEED,
        clonedSlidesCount = DEFAULT_CLONED_SLIDES_COUNT,
        initialSlide = 0,
        slidesToShow = DEFAULT_SLIDES_TO_SHOW,
        slidesToScroll = DEFAULT_SLIDES_TO_SCROLL,
        disableKeyboardEvents
    } = props;
    const galleryHolderRef = useRef<HTMLDivElement | null>(null);
    const isMobile = useIsMobile(true);
    const isMounted = useIsMounted();

    const [isSliderLoaded, setIsSliderLoaded, isSliderLoadedRef] = useStateRef(false);
    const [translateValue, setTranslateValue] = useState(0);
    const [currentIndex, setCurrentIndex, currentIndexRef] = useStateRef(initialSlide);
    const [breakpointSettings, setBreakpointSettings, breakpointSettingsRef] = useStateRef({
        breakpoint: 0,
        slidesToShow,
        slidesToScroll
    });

    const isTransitioning = useRef(false);

    const slidesListWithClones = isSliderLoaded
        ? [...props.slides.slice(-1 * clonedSlidesCount), ...props.slides, ...props.slides.slice(0, clonedSlidesCount)]
        : props.slides;

    /*
     * initialization, breakpoints and resizing logic
     */

    const setCurrentBreakpointSettings = () => {
        if (!props.breakpoints) {
            return;
        }

        const windowWidth = window.innerWidth;
        setBreakpointSettings(
            getClosestBreakpoint(
                [
                    {
                        breakpoint: 0,
                        slidesToShow,
                        slidesToScroll
                    },
                    ...props.breakpoints
                ],
                windowWidth
            )
        );
    };

    const initializeSlider = () => {
        setCurrentBreakpointSettings();

        if (!isSliderLoadedRef.current) {
            setTimeout(() => {
                setIsSliderLoaded(true);
                skipToSlide(currentIndexRef.current);
            }, 0);
        }
    };

    const onResize = () => {
        setCurrentBreakpointSettings();

        const isDelayed = Number.isFinite(props.onResizeDelay);
        if (isSliderLoaded) {
            isDelayed ? setTimeout(() => goToSlide(currentIndexRef.current), props.onResizeDelay) : goToSlide(currentIndexRef.current);
        }
    };

    // attach resize events
    useEffect(() => {
        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [currentIndex, isSliderLoaded, breakpointSettings]);

    /*
     * slider logic
     */

    // init
    useEffect(() => {
        if (isMounted) {
            initializeSlider();
        }
    }, [isMounted]);

    // attach keyboard events
    useEffect(() => {
        if (!disableKeyboardEvents) {
            document.addEventListener("keydown", handleSliderKeyDown);
        }
        return () => {
            if (!disableKeyboardEvents) {
                document.removeEventListener("keydown", handleSliderKeyDown);
            }
        };
    }, []);

    // handle isTransitioning - can be used to block new transitions
    useEffect(() => {
        const isSkippingSlide = preventAnimationRef.current;
        isTransitioning.current = !isSkippingSlide;
        const timeout = setTimeout(() => {
            isTransitioning.current = false;
        }, speed);

        return () => {
            clearTimeout(timeout);
        };
    }, [currentIndex]);

    const imagesCount = useRef(props.slides.length);
    const preventAnimationRef = useRef(false);

    const getGalleryWidth = () => {
        if (galleryHolderRef.current) {
            return galleryHolderRef.current.clientWidth;
        }
        return 0;
    };

    const onGestureEnd = (gestureDirection: GestureDirection) => {
        if (gestureDirection) {
            const nextSlide = currentIndexRef.current + gestureDirection;
            goToSlide(nextSlide);
        }
    };

    const {shouldBlockEventsRef} = useSliderTouchEvents(galleryHolderRef, initializeSlider, onGestureEnd, isMobile);

    /*
     * slide traversing methods
     */
    const setNewCurrentSlide = (slide: number) => {
        setCurrentIndex(slide);
        setTranslateValue(((clonedSlidesCount + slide) * getGalleryWidth() * -1) / breakpointSettingsRef.current.slidesToShow);
    };

    const goToSlide = (slide: number) => {
        let nextSlide = slide;

        if (slide < 0 || slide > imagesCount.current - 1) {
            // will land on a cloned slide - skip to the cloned slide on the other side immediately before animating
            const slideToSkipToBeforeAnim =
                slide < 0 ? imagesCount.current + currentIndexRef.current : slide - imagesCount.current - breakpointSettingsRef.current.slidesToScroll;
            skipToSlide(slideToSkipToBeforeAnim);

            // animate transition from cloned slide to next slide
            nextSlide =
                slide < 0
                    ? slideToSkipToBeforeAnim - breakpointSettingsRef.current.slidesToScroll
                    : slideToSkipToBeforeAnim + breakpointSettingsRef.current.slidesToScroll;
            setTimeout(() => goToSlide(nextSlide), 0);

            return;
        }

        props.onSlideChange?.(currentIndexRef.current, nextSlide);
        setNewCurrentSlide(nextSlide);
    };

    const skipToSlide = (slide: number) => {
        // skip animation and show selected slide
        preventAnimationRef.current = true;
        setNewCurrentSlide(slide);

        setTimeout(() => {
            preventAnimationRef.current = false;
        }, 0);
    };

    /*
     * slide traversing - arrow click + keyboard event handlers
     */

    const handleSliderKeyDown = useCallback((e: Event) => {
        // casting as unknown because "keydown" event does not accept KeyDownEventHandler O_o . I gave up
        const keyboardEvent = e as unknown as KeyboardEvent;
        if (keyboardEvent.key === "ArrowLeft") {
            slideLeft();
        }
        if (keyboardEvent.key === "ArrowRight") {
            slideRight();
        }
    }, []);

    const slideRight = useCallback(() => {
        if (shouldBlockEventsRef.current) {
            return;
        }
        if (isTransitioning.current) {
            return;
        }

        const newSlide = currentIndexRef.current + breakpointSettingsRef.current.slidesToScroll;
        goToSlide(newSlide);
    }, []);

    const handleSlideRightOnClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        slideRight();
    };

    const slideLeft = useCallback(() => {
        if (shouldBlockEventsRef.current) {
            return;
        }
        if (isTransitioning.current) {
            return;
        }

        const newSlide = currentIndexRef.current - breakpointSettingsRef.current.slidesToScroll;
        goToSlide(newSlide);
    }, []);

    const handleSlideLeftOnClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        slideLeft();
    };
    /*
     * misc
     */

    const preventClickEventsOnGesture: MouseEventHandler = (e) => {
        // prevent click events when gesture is detected
        if (shouldBlockEventsRef.current) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    /*
     * render
     */

    const slidesToShowDynamic = breakpointSettingsRef.current.slidesToShow;
    const transitionStyle: CSSProperties = !preventAnimationRef.current
        ? {transform: `translateX(${translateValue}px)`, transition: `transform ${speed}ms ${sliderTransition}`}
        : {transform: `translateX(${translateValue}px)`};
    const singleSlideWidth: CSSProperties = {"--minWidth-slide": `${slidesToShowDynamic ? 100 / slidesToShowDynamic : 100}%`};

    const galleryHolderCN = classNames(props.className, galleryHolder);
    const sliderCN = classNames(props.className, slider);
    const singleSliderCN = classNames(props.slideStyles, singleSlideWidth, singleSlide);

    return (
        <div ref={galleryHolderRef} onClick={preventClickEventsOnGesture} className={galleryHolderCN}>
            {props.arrows && props.slides.length > 1 && <span onClick={handleSlideLeftOnClick}>{props.arrows.left}</span>}
            <div className={sliderInnerWrap}>
                {!isSliderLoaded ? (
                    <div className={sliderCN} style={transitionStyle}>
                        <div key={clonedSlidesCount} className={singleSliderCN}>
                            {props.slides[initialSlide]}
                        </div>
                    </div>
                ) : (
                    <div className={sliderCN} style={transitionStyle}>
                        {slidesListWithClones.map((slide, index) => (
                            <div key={isSliderLoaded ? index + clonedSlidesCount : index} className={singleSliderCN}>
                                {slide}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {props.arrows && props.slides.length > 1 && <span onClick={handleSlideRightOnClick}>{props.arrows.right}</span>}
        </div>
    );
};
import React, {CSSProperties, FC, KeyboardEvent, MouseEventHandler, ReactNode, useCallback, useEffect, useRef, useState} from "react";
import classNames from "classnames";

import {useIsMobile, useIsMounted, useStateRef} from "@pg-mono/hooks";

import {GestureDirection, useSliderTouchEvents} from "../hooks/use_slider_touch_events";
import {getClosestBreakpoint} from "../utils/get_closest_breakpoint";

import {galleryHolder, singleSlide, slider, sliderInnerWrap} from "./SliderGallery.module.css";

export type ISliderTransition = "ease" | "linear" | "ease-in" | "ease-out" | "ease-in-out";
export type IBreakpointSettings = {breakpoint: number; slidesToShow: number; slidesToScroll: number};

interface IProps {
    slides: ReactNode[];
    arrows?: {
        left: JSX.Element;
        right: JSX.Element;
    };
    preventLazyLoad?: boolean;
    onSlideChange?: (currentSlideIndex: number, nextSlideIndex: number) => void;
    sliderTransition?: ISliderTransition;
    clonedSlidesCount?: number;
    forceInitialize?: true;
    onResizeDelay?: number; // miliseconds
    initialSlide?: number;
    disableKeyboardEvents?: boolean;
    className?: string;
    slideStyles?: CSSProperties | CSSProperties[];

    /// slick-slider-like options:
    slidesToShow?: number;
    slidesToScroll?: number;
    speed?: number;
    breakpoints?: IBreakpointSettings[];
}

const DEFAULT_SPEED = 300;
const DEFAULT_SLIDER_TRANSITION = "ease-out";
const DEFAULT_CLONED_SLIDES_COUNT = 2;
const DEFAULT_SLIDES_TO_SHOW = 1;
const DEFAULT_SLIDES_TO_SCROLL = 1;

// I am using a lot of refs here to avoid potential issues with stale state being used inside event listeners. Reference to equal state value will always be up to date.

export const SliderGallery: FC<IProps> = (props) => {
    const {
        sliderTransition = DEFAULT_SLIDER_TRANSITION,
        speed = DEFAULT_SPEED,
        clonedSlidesCount = DEFAULT_CLONED_SLIDES_COUNT,
        initialSlide = 0,
        slidesToShow = DEFAULT_SLIDES_TO_SHOW,
        slidesToScroll = DEFAULT_SLIDES_TO_SCROLL,
        disableKeyboardEvents
    } = props;
    const galleryHolderRef = useRef<HTMLDivElement | null>(null);
    const isMobile = useIsMobile(true);
    const isMounted = useIsMounted();

    const [isSliderLoaded, setIsSliderLoaded, isSliderLoadedRef] = useStateRef(false);
    const [translateValue, setTranslateValue] = useState(0);
    const [currentIndex, setCurrentIndex, currentIndexRef] = useStateRef(initialSlide);
    const [breakpointSettings, setBreakpointSettings, breakpointSettingsRef] = useStateRef({
        breakpoint: 0,
        slidesToShow,
        slidesToScroll
    });

    const isTransitioning = useRef(false);

    const slidesListWithClones = isSliderLoaded
        ? [...props.slides.slice(-1 * clonedSlidesCount), ...props.slides, ...props.slides.slice(0, clonedSlidesCount)]
        : props.slides;

    /*
     * initialization, breakpoints and resizing logic
     */

    const setCurrentBreakpointSettings = () => {
        if (!props.breakpoints) {
            return;
        }

        const windowWidth = window.innerWidth;
        setBreakpointSettings(
            getClosestBreakpoint(
                [
                    {
                        breakpoint: 0,
                        slidesToShow,
                        slidesToScroll
                    },
                    ...props.breakpoints
                ],
                windowWidth
            )
        );
    };

    const initializeSlider = () => {
        setCurrentBreakpointSettings();

        if (!isSliderLoadedRef.current) {
            setTimeout(() => {
                setIsSliderLoaded(true);
                skipToSlide(currentIndexRef.current);
            }, 0);
        }
    };

    const onResize = () => {
        setCurrentBreakpointSettings();

        const isDelayed = Number.isFinite(props.onResizeDelay);
        if (isSliderLoaded) {
            isDelayed ? setTimeout(() => goToSlide(currentIndexRef.current), props.onResizeDelay) : goToSlide(currentIndexRef.current);
        }
    };

    // attach resize events
    useEffect(() => {
        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [currentIndex, isSliderLoaded, breakpointSettings]);

    /*
     * slider logic
     */

    // init
    useEffect(() => {
        if (isMounted) {
            initializeSlider();
        }
    }, [isMounted]);

    // attach keyboard events
    useEffect(() => {
        if (!disableKeyboardEvents) {
            document.addEventListener("keydown", handleSliderKeyDown);
        }
        return () => {
            if (!disableKeyboardEvents) {
                document.removeEventListener("keydown", handleSliderKeyDown);
            }
        };
    }, []);

    // handle isTransitioning - can be used to block new transitions
    useEffect(() => {
        const isSkippingSlide = preventAnimationRef.current;
        isTransitioning.current = !isSkippingSlide;
        const timeout = setTimeout(() => {
            isTransitioning.current = false;
        }, speed);

        return () => {
            clearTimeout(timeout);
        };
    }, [currentIndex]);

    const imagesCount = useRef(props.slides.length);
    const preventAnimationRef = useRef(false);

    const getGalleryWidth = () => {
        if (galleryHolderRef.current) {
            return galleryHolderRef.current.clientWidth;
        }
        return 0;
    };

    const onGestureEnd = (gestureDirection: GestureDirection) => {
        if (gestureDirection) {
            const nextSlide = currentIndexRef.current + gestureDirection;
            goToSlide(nextSlide);
        }
    };

    const {shouldBlockEventsRef} = useSliderTouchEvents(galleryHolderRef, initializeSlider, onGestureEnd, isMobile);

    /*
     * slide traversing methods
     */
    const setNewCurrentSlide = (slide: number) => {
        setCurrentIndex(slide);
        setTranslateValue(((clonedSlidesCount + slide) * getGalleryWidth() * -1) / breakpointSettingsRef.current.slidesToShow);
    };

    const goToSlide = (slide: number) => {
        let nextSlide = slide;

        if (slide < 0 || slide > imagesCount.current - 1) {
            // will land on a cloned slide - skip to the cloned slide on the other side immediately before animating
            const slideToSkipToBeforeAnim =
                slide < 0 ? imagesCount.current + currentIndexRef.current : slide - imagesCount.current - breakpointSettingsRef.current.slidesToScroll;
            skipToSlide(slideToSkipToBeforeAnim);

            // animate transition from cloned slide to next slide
            nextSlide =
                slide < 0
                    ? slideToSkipToBeforeAnim - breakpointSettingsRef.current.slidesToScroll
                    : slideToSkipToBeforeAnim + breakpointSettingsRef.current.slidesToScroll;
            setTimeout(() => goToSlide(nextSlide), 0);

            return;
        }

        props.onSlideChange?.(currentIndexRef.current, nextSlide);
        setNewCurrentSlide(nextSlide);
    };

    const skipToSlide = (slide: number) => {
        // skip animation and show selected slide
        preventAnimationRef.current = true;
        setNewCurrentSlide(slide);

        setTimeout(() => {
            preventAnimationRef.current = false;
        }, 0);
    };

    /*
     * slide traversing - arrow click + keyboard event handlers
     */

    const handleSliderKeyDown = useCallback((e: Event) => {
        // casting as unknown because "keydown" event does not accept KeyDownEventHandler O_o . I gave up
        const keyboardEvent = e as unknown as KeyboardEvent;
        if (keyboardEvent.key === "ArrowLeft") {
            slideLeft();
        }
        if (keyboardEvent.key === "ArrowRight") {
            slideRight();
        }
    }, []);

    const slideRight = useCallback(() => {
        if (shouldBlockEventsRef.current) {
            return;
        }
        if (isTransitioning.current) {
            return;
        }

        const newSlide = currentIndexRef.current + breakpointSettingsRef.current.slidesToScroll;
        goToSlide(newSlide);
    }, []);

    const handleSlideRightOnClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        slideRight();
    };

    const slideLeft = useCallback(() => {
        if (shouldBlockEventsRef.current) {
            return;
        }
        if (isTransitioning.current) {
            return;
        }

        const newSlide = currentIndexRef.current - breakpointSettingsRef.current.slidesToScroll;
        goToSlide(newSlide);
    }, []);

    const handleSlideLeftOnClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        slideLeft();
    };
    /*
     * misc
     */

    const preventClickEventsOnGesture: MouseEventHandler = (e) => {
        // prevent click events when gesture is detected
        if (shouldBlockEventsRef.current) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    /*
     * render
     */

    const slidesToShowDynamic = breakpointSettingsRef.current.slidesToShow;
    const transitionStyle: CSSProperties = !preventAnimationRef.current
        ? {transform: `translateX(${translateValue}px)`, transition: `transform ${speed}ms ${sliderTransition}`}
        : {transform: `translateX(${translateValue}px)`};
    const singleSlideWidth: CSSProperties = {"--minWidth-slide": `${slidesToShowDynamic ? 100 / slidesToShowDynamic : 100}%`};

    const galleryHolderCN = classNames(props.className, galleryHolder);
    const sliderCN = classNames(props.className, slider);
    const singleSliderCN = classNames(props.slideStyles, singleSlideWidth, singleSlide);

    return (
        <div ref={galleryHolderRef} onClick={preventClickEventsOnGesture} className={galleryHolderCN}>
            {props.arrows && props.slides.length > 1 && <span onClick={handleSlideLeftOnClick}>{props.arrows.left}</span>}
            <div className={sliderInnerWrap}>
                {!isSliderLoaded ? (
                    <div className={sliderCN} style={transitionStyle}>
                        <div key={clonedSlidesCount} className={singleSliderCN}>
                            {props.slides[initialSlide]}
                        </div>
                    </div>
                ) : (
                    <div className={sliderCN} style={transitionStyle}>
                        {slidesListWithClones.map((slide, index) => (
                            <div key={isSliderLoaded ? index + clonedSlidesCount : index} className={singleSliderCN}>
                                {slide}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {props.arrows && props.slides.length > 1 && <span onClick={handleSlideRightOnClick}>{props.arrows.right}</span>}
        </div>
    );
};
