import {UserComAttribute, UserComCustomAttribute, UserComEventNames, userComUpdateUserAttributes} from "@pg-mono/user-com";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IUserProfile} from "../../user/ts/interfaces/IUserProfile";

type IParams = {
    eventName: UserComEventNames.UserLead | UserComEventNames.UserLeadModularHouses;
    sentLead: {
        email?: string;
        phone?: string;
    };
    userProfile: IUserProfile | null;
    additionalData?: Partial<Record<UserComCustomAttribute, string>>;
};

export function updateUserComAfterLead(params: IParams) {
    const {sentLead, userProfile} = params;
    const {uuid} = userProfile || {};
    const {email, phone} = sentLead;

    /**
     * Check if the email and phone exists to be sure that the data being sent is correct.
     * However, this should not happen, since the email and phone are required fields in the lead form.
     */
    if (!email || !phone) {
        const errorMessage = "updateUserComAfterLead: Email or phone is missing";
        notifyBugsnag(new Error(errorMessage), errorMessage);
        return;
    }

    const data: Partial<Record<UserComAttribute, string>> & Partial<Record<UserComCustomAttribute, string>> = {
        [UserComAttribute.EMAIL]: email,
        [UserComAttribute.PHONE_NUMBER]: phone
    };

    if (uuid) {
        data[UserComAttribute.USER_ID] = uuid;
    }

    userComUpdateUserAttributes({event_name: UserComEventNames.UserLead, ...data});
}
import {UserComAttribute, UserComCustomAttribute, UserComEventNames, userComUpdateUserAttributes} from "@pg-mono/user-com";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IUserProfile} from "../../user/ts/interfaces/IUserProfile";

type IParams = {
    eventName: UserComEventNames.UserLead | UserComEventNames.UserLeadModularHouses;
    sentLead: {
        email?: string;
        phone?: string;
    };
    userProfile: IUserProfile | null;
    additionalData?: Partial<Record<UserComCustomAttribute, string>>;
};

export function updateUserComAfterLead(params: IParams) {
    const {sentLead, userProfile} = params;
    const {uuid} = userProfile || {};
    const {email, phone} = sentLead;

    /**
     * Check if the email and phone exists to be sure that the data being sent is correct.
     * However, this should not happen, since the email and phone are required fields in the lead form.
     */
    if (!email || !phone) {
        const errorMessage = "updateUserComAfterLead: Email or phone is missing";
        notifyBugsnag(new Error(errorMessage), errorMessage);
        return;
    }

    const data: Partial<Record<UserComAttribute, string>> & Partial<Record<UserComCustomAttribute, string>> = {
        [UserComAttribute.EMAIL]: email,
        [UserComAttribute.PHONE_NUMBER]: phone
    };

    if (uuid) {
        data[UserComAttribute.USER_ID] = uuid;
    }

    userComUpdateUserAttributes({event_name: UserComEventNames.UserLead, ...data});
}
