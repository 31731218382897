import React, {MouseEvent, useId} from "react";
import {useTheme} from "@emotion/react";

import {Text} from "@pg-design/text-module";
import {FormikForm} from "@pg-mono/formik-utils";

import {RodoWithdrawConsent} from "../components/RodoWithdrawConsent";

interface IProps {
    name: string;
    onFieldClick?: (event: MouseEvent<HTMLInputElement>) => void;
    className?: string;
}

export const NotificationConsentField = (props: IProps) => {
    const {name, onFieldClick, className} = props;
    const theme = useTheme();
    const id = useId();

    return (
        <FormikForm.Checkbox
            name={name}
            labelContent={
                <Text as="span" variant="info_txt_1" color={theme.colors.secondary}>
                    Poproszę o przesyłanie szczegółów dotyczących nowych nieruchomości i rekomendacji drogą mailową lub telefoniczną. <RodoWithdrawConsent />
                </Text>
            }
            id={id}
            onClick={onFieldClick}
            className={className}
            checkedMarkColor="#02D054"
        />
    );
};
import React, {MouseEvent, useId} from "react";
import {useTheme} from "@emotion/react";

import {Text} from "@pg-design/text-module";
import {FormikForm} from "@pg-mono/formik-utils";

import {RodoWithdrawConsent} from "../components/RodoWithdrawConsent";

interface IProps {
    name: string;
    onFieldClick?: (event: MouseEvent<HTMLInputElement>) => void;
    className?: string;
}

export const NotificationConsentField = (props: IProps) => {
    const {name, onFieldClick, className} = props;
    const theme = useTheme();
    const id = useId();

    return (
        <FormikForm.Checkbox
            name={name}
            labelContent={
                <Text as="span" variant="info_txt_1" color={theme.colors.secondary}>
                    Poproszę o przesyłanie szczegółów dotyczących nowych nieruchomości i rekomendacji drogą mailową lub telefoniczną. <RodoWithdrawConsent />
                </Text>
            }
            id={id}
            onClick={onFieldClick}
            className={className}
            checkedMarkColor="#02D054"
        />
    );
};
