import {consoleError} from "@pg-mono/logger";
import {getRequest} from "@pg-mono/request";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../../client/client_rp_request_meta";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {getUserApiRequestMeta} from "../utils/get_user_api_request_meta";

export const fetchUserUnsubscribeLink = (): Promise<{unsubscribe_url_rp: string; unsubscribe_url_gh: string}> => {
    const userApiMeta = getUserApiRequestMeta(clientRpRequestMeta);

    return getRequest(userApiMeta, apiUserLink.unsubscribe.getLink).catch((error) => {
        const message = "Error in fetchUserUnsubscribeLink";

        consoleError(message, error.message);
        notifyBugsnag(error, message, error.responseError ?? {});
    });
};
import {consoleError} from "@pg-mono/logger";
import {getRequest} from "@pg-mono/request";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../../client/client_rp_request_meta";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {getUserApiRequestMeta} from "../utils/get_user_api_request_meta";

export const fetchUserUnsubscribeLink = (): Promise<{unsubscribe_url_rp: string; unsubscribe_url_gh: string}> => {
    const userApiMeta = getUserApiRequestMeta(clientRpRequestMeta);

    return getRequest(userApiMeta, apiUserLink.unsubscribe.getLink).catch((error) => {
        const message = "Error in fetchUserUnsubscribeLink";

        consoleError(message, error.message);
        notifyBugsnag(error, message, error.responseError ?? {});
    });
};
