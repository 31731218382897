import {css} from "@emotion/react";

import {Popover} from "@pg-design/notifications";
import {Text} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

interface IProps {
    popoverZindex?: number;
}

export function RodoWithdrawConsent(props: IProps) {
    const {popoverZindex} = props;
    const {isMobile} = useUserDevice();

    return (
        <span onClick={(e) => e.preventDefault()}>
            <Popover
                isActive
                popoverPlace={isMobile ? "top" : "left"}
                body={
                    <Text variant="info_txt_2">
                        Jeśli będziesz chciał(a) odwołać zgodę: skontaktuj się z nami lub wyślij komendę STOP na numer telefonu <br /> +48 664 078 606, albo
                        kliknij w link służący do odwołania zgody znajdujący się w naszym newsletterze.
                    </Text>
                }
                toggleClickHandler
                toggleOnClickOutside
                inline
                zIndex={popoverZindex}
            >
                <span css={propertyNotificationConsentPopupHandle}>Zgodę mogę zawsze odwołać.</span>
            </Popover>
        </span>
    );
}

const propertyNotificationConsentPopupHandle = css`
    text-decoration: underline;
`;
import {css} from "@emotion/react";

import {Popover} from "@pg-design/notifications";
import {Text} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

interface IProps {
    popoverZindex?: number;
}

export function RodoWithdrawConsent(props: IProps) {
    const {popoverZindex} = props;
    const {isMobile} = useUserDevice();

    return (
        <span onClick={(e) => e.preventDefault()}>
            <Popover
                isActive
                popoverPlace={isMobile ? "top" : "left"}
                body={
                    <Text variant="info_txt_2">
                        Jeśli będziesz chciał(a) odwołać zgodę: skontaktuj się z nami lub wyślij komendę STOP na numer telefonu <br /> +48 664 078 606, albo
                        kliknij w link służący do odwołania zgody znajdujący się w naszym newsletterze.
                    </Text>
                }
                toggleClickHandler
                toggleOnClickOutside
                inline
                zIndex={popoverZindex}
            >
                <span css={propertyNotificationConsentPopupHandle}>Zgodę mogę zawsze odwołać.</span>
            </Popover>
        </span>
    );
}

const propertyNotificationConsentPopupHandle = css`
    text-decoration: underline;
`;
