import {IPhoneFieldValue} from "@pg-design/phone-input-module";

export function isPhoneEmpty(phone: IPhoneFieldValue | null | undefined) {
    return !phone || !phone.number;
}
import {IPhoneFieldValue} from "@pg-design/phone-input-module";

export function isPhoneEmpty(phone: IPhoneFieldValue | null | undefined) {
    return !phone || !phone.number;
}
