import {useSyncExternalStore} from "react";
import lscache from "lscache";

import {ARTICLE_ATTACHMENT_LS_KEY} from "../../app/constants/storage_keys";
import {isBrowser} from "../../app/read_rp_environment_variables";

const TWO_HOURS = 2 * 60;

/**
 * LS stores temporary information about user's attempts to download attachments. In case we fail to login the user but
 * the access was granted, we want to allow to download further attachments without bothering him. We also store the email for future user.com events
 */
export const useArticleAttachmentsLSStore = () => {
    const email = useSyncExternalStore(articleAttachmentLSStore.subscribe, articleAttachmentLSStore.getSnapshot, articleAttachmentLSStore.getServerSnapshot);

    return {
        email,
        storeEmail: articleAttachmentLSStore.storeEmail
    };
};

const articleAttachmentLSStore = {
    storeEmail: function (email: string) {
        lscache.set(ARTICLE_ATTACHMENT_LS_KEY, email, TWO_HOURS);
        isBrowser && window.dispatchEvent(new StorageEvent("storage", {key: ARTICLE_ATTACHMENT_LS_KEY, newValue: email}));
    },
    // api for useSyncExternalStore:
    getSnapshot: function () {
        return lscache.get(ARTICLE_ATTACHMENT_LS_KEY);
    },
    getServerSnapshot: function () {
        return null;
    },
    subscribe: (listener: () => void) => {
        isBrowser && window.addEventListener("storage", listener);
        return () => void window.removeEventListener("storage", listener);
    }
};

export const removeArticleAttachmentLSStore = () => {
    lscache.remove(ARTICLE_ATTACHMENT_LS_KEY);
};
import {useSyncExternalStore} from "react";
import lscache from "lscache";

import {ARTICLE_ATTACHMENT_LS_KEY} from "../../app/constants/storage_keys";
import {isBrowser} from "../../app/read_rp_environment_variables";

const TWO_HOURS = 2 * 60;

/**
 * LS stores temporary information about user's attempts to download attachments. In case we fail to login the user but
 * the access was granted, we want to allow to download further attachments without bothering him. We also store the email for future user.com events
 */
export const useArticleAttachmentsLSStore = () => {
    const email = useSyncExternalStore(articleAttachmentLSStore.subscribe, articleAttachmentLSStore.getSnapshot, articleAttachmentLSStore.getServerSnapshot);

    return {
        email,
        storeEmail: articleAttachmentLSStore.storeEmail
    };
};

const articleAttachmentLSStore = {
    storeEmail: function (email: string) {
        lscache.set(ARTICLE_ATTACHMENT_LS_KEY, email, TWO_HOURS);
        isBrowser && window.dispatchEvent(new StorageEvent("storage", {key: ARTICLE_ATTACHMENT_LS_KEY, newValue: email}));
    },
    // api for useSyncExternalStore:
    getSnapshot: function () {
        return lscache.get(ARTICLE_ATTACHMENT_LS_KEY);
    },
    getServerSnapshot: function () {
        return null;
    },
    subscribe: (listener: () => void) => {
        isBrowser && window.addEventListener("storage", listener);
        return () => void window.removeEventListener("storage", listener);
    }
};

export const removeArticleAttachmentLSStore = () => {
    lscache.remove(ARTICLE_ATTACHMENT_LS_KEY);
};
