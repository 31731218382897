export const validationMessages = {
    email: "podaj poprawny adres e-mail",
    number: "wartość musi być liczbą",
    required: "to pole jest wymagane",
    phone: "nieprawidłowy numer telefonu"
};
export const validationMessages = {
    email: "podaj poprawny adres e-mail",
    number: "wartość musi być liczbą",
    required: "to pole jest wymagane",
    phone: "nieprawidłowy numer telefonu"
};
