import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const RoomsIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5.8 4.1h1.067v.533H7.4V3.567H5.267v1.066H5.8V4.1Z" />
            <path d="M8.467 4.633V2.5H1.533v2.133H1v3.2h.533V6.767h6.934v1.066H9v-3.2h-.533Zm-.534-1.6v1.6h-3.2V3.567H2.6v1.066h-.533v-1.6h5.866ZM4.2 4.633H3.133V4.1H4.2v.533Zm4.267 1.6H1.533V5.167h6.934v1.066Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const RoomsIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5.8 4.1h1.067v.533H7.4V3.567H5.267v1.066H5.8V4.1Z" />
            <path d="M8.467 4.633V2.5H1.533v2.133H1v3.2h.533V6.767h6.934v1.066H9v-3.2h-.533Zm-.534-1.6v1.6h-3.2V3.567H2.6v1.066h-.533v-1.6h5.866ZM4.2 4.633H3.133V4.1H4.2v.533Zm4.267 1.6H1.533V5.167h6.934v1.066Z" />
        </SvgIcon>
    );
};
