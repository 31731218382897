import {ReactElement} from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, flex, flexAbsoluteCenter, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {GreyInfoTable, GreyInfoTableVariant} from "./GreyInfoTable";

interface IInfoItem {
    value: ReactElement | string;
    label: ReactElement | string;
    blockValueTranslation?: boolean;
}

interface IProps {
    infoItems: IInfoItem[];
    className?: string;
    additionalInfoItems?: IInfoItem[];
}

// TODO Consider adding this to DS or remove this comment
export function ShortInfoBar(props: IProps) {
    const {infoItems, className, additionalInfoItems} = props;

    return (
        <div className={className}>
            <div css={[infoHolder, additionalInfoItems ? borderRadius(1, 1, 0, 0) : borderRadius()]}>
                {infoItems.map((item, index) => {
                    const {value, label, blockValueTranslation} = item;

                    return (
                        <div key={index} css={infoItem}>
                            <Text color="#fff" variant="info_txt_1" as="span" strong mb="0.4rem" className={blockValueTranslation ? "bt" : ""}>
                                {value}
                            </Text>

                            <Text color="#fff" variant="info_txt_3">
                                {label}
                            </Text>
                        </div>
                    );
                })}
            </div>

            {additionalInfoItems && <GreyInfoTable infoItems={additionalInfoItems} variant={GreyInfoTableVariant.BOTTOM} />}
        </div>
    );
}

// Styles

const infoHolder = (theme: Theme) => css`
    ${flex("center", "space-between")};
    ${p(2)};
    background: ${theme.colors.gray[800]};
`;

const infoItem = css`
    ${flexAbsoluteCenter};
    flex-direction: column;
`;
import {ReactElement} from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, flex, flexAbsoluteCenter, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {GreyInfoTable, GreyInfoTableVariant} from "./GreyInfoTable";

interface IInfoItem {
    value: ReactElement | string;
    label: ReactElement | string;
    blockValueTranslation?: boolean;
}

interface IProps {
    infoItems: IInfoItem[];
    className?: string;
    additionalInfoItems?: IInfoItem[];
}

// TODO Consider adding this to DS or remove this comment
export function ShortInfoBar(props: IProps) {
    const {infoItems, className, additionalInfoItems} = props;

    return (
        <div className={className}>
            <div css={[infoHolder, additionalInfoItems ? borderRadius(1, 1, 0, 0) : borderRadius()]}>
                {infoItems.map((item, index) => {
                    const {value, label, blockValueTranslation} = item;

                    return (
                        <div key={index} css={infoItem}>
                            <Text color="#fff" variant="info_txt_1" as="span" strong mb="0.4rem" className={blockValueTranslation ? "bt" : ""}>
                                {value}
                            </Text>

                            <Text color="#fff" variant="info_txt_3">
                                {label}
                            </Text>
                        </div>
                    );
                })}
            </div>

            {additionalInfoItems && <GreyInfoTable infoItems={additionalInfoItems} variant={GreyInfoTableVariant.BOTTOM} />}
        </div>
    );
}

// Styles

const infoHolder = (theme: Theme) => css`
    ${flex("center", "space-between")};
    ${p(2)};
    background: ${theme.colors.gray[800]};
`;

const infoItem = css`
    ${flexAbsoluteCenter};
    flex-direction: column;
`;
