import React, {ReactNode} from "react";
import {css} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons-module";
import {Popover} from "@pg-design/notifications";
import {useUserDevice} from "@pg-mono/user-device";

interface IProps {
    isActive: boolean;
    children: ReactNode;
    onClose: () => void;
    zIndex?: number;
    popoverPlace?: Parameters<typeof Popover>[0]["popoverPlace"];
    popoverPlaceMobile?: Parameters<typeof Popover>[0]["popoverPlace"];
}

export const EmailVerificationPopover = (props: IProps) => {
    const {isMobile} = useUserDevice();

    const popoverPlaceDesktop = props.popoverPlace || "right";
    const popoverPlaceMobile = props.popoverPlaceMobile || "bottom";

    return (
        <Popover
            isActive={props.isActive}
            body={
                <>
                    Twój adres e-mail wydaje się być nieprawidłowy.
                    <CloseIcon fill="#fff" size="1.6" onClick={props.onClose} css={closeIconStyle} />
                </>
            }
            type="info"
            popoverPlace={isMobile ? popoverPlaceMobile : popoverPlaceDesktop}
            zIndex={props.zIndex || 1000}
            openOnMount
        >
            {props.children}
        </Popover>
    );
};

const closeIconStyle = css`
    position: absolute;
    top: ${calculateRemSize(1)};
    right: ${calculateRemSize(1)};
    cursor: pointer;
`;
import React, {ReactNode} from "react";
import {css} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons-module";
import {Popover} from "@pg-design/notifications";
import {useUserDevice} from "@pg-mono/user-device";

interface IProps {
    isActive: boolean;
    children: ReactNode;
    onClose: () => void;
    zIndex?: number;
    popoverPlace?: Parameters<typeof Popover>[0]["popoverPlace"];
    popoverPlaceMobile?: Parameters<typeof Popover>[0]["popoverPlace"];
}

export const EmailVerificationPopover = (props: IProps) => {
    const {isMobile} = useUserDevice();

    const popoverPlaceDesktop = props.popoverPlace || "right";
    const popoverPlaceMobile = props.popoverPlaceMobile || "bottom";

    return (
        <Popover
            isActive={props.isActive}
            body={
                <>
                    Twój adres e-mail wydaje się być nieprawidłowy.
                    <CloseIcon fill="#fff" size="1.6" onClick={props.onClose} css={closeIconStyle} />
                </>
            }
            type="info"
            popoverPlace={isMobile ? popoverPlaceMobile : popoverPlaceDesktop}
            zIndex={props.zIndex || 1000}
            openOnMount
        >
            {props.children}
        </Popover>
    );
};

const closeIconStyle = css`
    position: absolute;
    top: ${calculateRemSize(1)};
    right: ${calculateRemSize(1)};
    cursor: pointer;
`;
