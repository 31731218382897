import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PriceIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.434 5.263v-3.2H6.56V1h-.533v1.066h-.79L1.88 5.423 1.5 5.8l2.821 2.82.379-.38L2.257 5.8l3.2-3.2h.569v.577a1.065 1.065 0 1 0 .533-.005V2.6H7.9v2.442L4.32 8.623 4.7 9l3.734-3.737ZM6.836 4.2a.533.533 0 1 1-.809-.454V4.2h.533v-.46a.532.532 0 0 1 .276.46Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PriceIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.434 5.263v-3.2H6.56V1h-.533v1.066h-.79L1.88 5.423 1.5 5.8l2.821 2.82.379-.38L2.257 5.8l3.2-3.2h.569v.577a1.065 1.065 0 1 0 .533-.005V2.6H7.9v2.442L4.32 8.623 4.7 9l3.734-3.737ZM6.836 4.2a.533.533 0 1 1-.809-.454V4.2h.533v-.46a.532.532 0 0 1 .276.46Z" />
        </SvgIcon>
    );
};
