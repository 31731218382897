import React from "react";
import styled from "@emotion/styled";

import {p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {useIsMounted} from "@pg-mono/hooks";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";

interface IProps {
    isDark?: boolean;
    vendor?: IVendorDetailApplication | null;
    isRenters?: boolean;
    isCredipass?: boolean;
    isModularHouse?: boolean;
    offerPrivacyPolicyUrl?: string | null;
    offerPersonalDataProcessorName?: string;
    offerPersonalDataProcessorUrl?: string | null;
    className?: string;
}

export const RodoFormInfo = (props: IProps) => {
    const rodoTermsUrl = rpAppLink.rodoTerms.base();
    const termsOfServiceUrl = rpAppLink.termsOfService.base();
    const isMounted = useIsMounted(); // link appears after component has been mounted, due to seo decision
    const privacyPolicyUrl =
        props.vendor?.configuration.privacy_policy_file ||
        props.offerPersonalDataProcessorUrl ||
        props.offerPrivacyPolicyUrl ||
        props.vendor?.configuration.privacy_policy_url;

    const showOfferPersonalDataProcessor = !!props.offerPersonalDataProcessorName && !!props.offerPersonalDataProcessorUrl;

    return (
        <RodoHolder isDark={!!props.isDark} className={props.className}>
            <Text variant="info_txt_2" align="center" className="bt">
                Administratorami Twoich danych osobowych są:
                <br />
                {props.vendor && !showOfferPersonalDataProcessor && (
                    <>
                        <span className="bt">{`${props.vendor.name} w zakresie odpowiedzi na zapytanie. `}</span>
                        {isMounted && privacyPolicyUrl && !showOfferPersonalDataProcessor && (
                            <a href={privacyPolicyUrl} target="_blank">
                                Dowiedz się więcej o przetwarzaniu Twoich danych.
                            </a>
                        )}
                    </>
                )}
                {showOfferPersonalDataProcessor && props.offerPersonalDataProcessorUrl && (
                    <>
                        <span className="bt">{`${props.offerPersonalDataProcessorName} w zakresie odpowiedzi na zapytanie. `}</span>
                        {isMounted && (
                            <a href={props.offerPersonalDataProcessorUrl} target="_blank">
                                Dowiedz się więcej o przetwarzaniu Twoich danych.
                            </a>
                        )}
                    </>
                )}
                <br />
                Property Group sp. z o.o. w zakresie usług wskazanych w{" "}
                <a href={termsOfServiceUrl} target="_blank">
                    regulaminie
                </a>
                .{" "}
                <a href={rodoTermsUrl} target="_blank">
                    Dowiedz się więcej o przetwarzaniu Twoich danych.
                </a>
                {props.isCredipass && (
                    <>
                        <br />
                        Credipass Polska S. A. w zakresie odpowiedzi na zapytanie kredytowe.{" "}
                        <a href="https://credipass.pl/polityka-prywatnosci/" target="_blank">
                            Dowiedz się więcej o przetwarzaniu Twoich danych.
                        </a>
                    </>
                )}
                {props.isModularHouse && <> Oraz firma, której dotyczy zapytanie</>}
            </Text>
        </RodoHolder>
    );
};

interface IRodoHolderProps {
    isDark: boolean;
}

const RodoHolder = styled.div<IRodoHolderProps>`
    color: ${(props) => (props.isDark ? "#fff" : props.theme.colors.gray[700])};
    text-align: center;
    ${p(2, 1)};

    a {
        text-decoration: underline;
        color: ${(props) => (props.isDark ? "#fff" : props.theme.colors.gray[700])};

        &:hover,
        &:focus,
        &:active {
            color: ${(props) => (props.isDark ? "#fff" : props.theme.colors.gray[700])};
        }
    }
`;
import React from "react";
import styled from "@emotion/styled";

import {p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {useIsMounted} from "@pg-mono/hooks";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";

interface IProps {
    isDark?: boolean;
    vendor?: IVendorDetailApplication | null;
    isRenters?: boolean;
    isCredipass?: boolean;
    isModularHouse?: boolean;
    offerPrivacyPolicyUrl?: string | null;
    offerPersonalDataProcessorName?: string;
    offerPersonalDataProcessorUrl?: string | null;
    className?: string;
}

export const RodoFormInfo = (props: IProps) => {
    const rodoTermsUrl = rpAppLink.rodoTerms.base();
    const termsOfServiceUrl = rpAppLink.termsOfService.base();
    const isMounted = useIsMounted(); // link appears after component has been mounted, due to seo decision
    const privacyPolicyUrl =
        props.vendor?.configuration.privacy_policy_file ||
        props.offerPersonalDataProcessorUrl ||
        props.offerPrivacyPolicyUrl ||
        props.vendor?.configuration.privacy_policy_url;

    const showOfferPersonalDataProcessor = !!props.offerPersonalDataProcessorName && !!props.offerPersonalDataProcessorUrl;

    return (
        <RodoHolder isDark={!!props.isDark} className={props.className}>
            <Text variant="info_txt_2" align="center" className="bt">
                Administratorami Twoich danych osobowych są:
                <br />
                {props.vendor && !showOfferPersonalDataProcessor && (
                    <>
                        <span className="bt">{`${props.vendor.name} w zakresie odpowiedzi na zapytanie. `}</span>
                        {isMounted && privacyPolicyUrl && !showOfferPersonalDataProcessor && (
                            <a href={privacyPolicyUrl} target="_blank">
                                Dowiedz się więcej o przetwarzaniu Twoich danych.
                            </a>
                        )}
                    </>
                )}
                {showOfferPersonalDataProcessor && props.offerPersonalDataProcessorUrl && (
                    <>
                        <span className="bt">{`${props.offerPersonalDataProcessorName} w zakresie odpowiedzi na zapytanie. `}</span>
                        {isMounted && (
                            <a href={props.offerPersonalDataProcessorUrl} target="_blank">
                                Dowiedz się więcej o przetwarzaniu Twoich danych.
                            </a>
                        )}
                    </>
                )}
                <br />
                Property Group sp. z o.o. w zakresie usług wskazanych w{" "}
                <a href={termsOfServiceUrl} target="_blank">
                    regulaminie
                </a>
                .{" "}
                <a href={rodoTermsUrl} target="_blank">
                    Dowiedz się więcej o przetwarzaniu Twoich danych.
                </a>
                {props.isCredipass && (
                    <>
                        <br />
                        Credipass Polska S. A. w zakresie odpowiedzi na zapytanie kredytowe.{" "}
                        <a href="https://credipass.pl/polityka-prywatnosci/" target="_blank">
                            Dowiedz się więcej o przetwarzaniu Twoich danych.
                        </a>
                    </>
                )}
                {props.isModularHouse && <> Oraz firma, której dotyczy zapytanie</>}
            </Text>
        </RodoHolder>
    );
};

interface IRodoHolderProps {
    isDark: boolean;
}

const RodoHolder = styled.div<IRodoHolderProps>`
    color: ${(props) => (props.isDark ? "#fff" : props.theme.colors.gray[700])};
    text-align: center;
    ${p(2, 1)};

    a {
        text-decoration: underline;
        color: ${(props) => (props.isDark ? "#fff" : props.theme.colors.gray[700])};

        &:hover,
        &:focus,
        &:active {
            color: ${(props) => (props.isDark ? "#fff" : props.theme.colors.gray[700])};
        }
    }
`;
