import {Attribute} from "../types/Attribute";
import {CustomAttribute} from "../types/CustomAttribute";
import {EventNames} from "../types/EventNames";
import {userComUserengage} from "../user_com_userengage";
import {updateUserAttributes} from "./update_user_attributes";

export const userRegister = (
    uuid: string,
    email: string,
    emailMarketingConsent: boolean,
    phoneMarketingConsent: boolean,
    unsubscribeUrlRP: string,
    unsubscribeUrlGH: string
) => {
    const payload = {
        event_name: EventNames.UserRegistration,
        [Attribute.USER_ID]: uuid,
        [Attribute.EMAIL]: email,
        [CustomAttribute.EMAIL_MARKETING_CONSENT]: emailMarketingConsent ? "tak" : "nie",
        [CustomAttribute.PHONE_MARKETING_CONSENT]: phoneMarketingConsent ? "tak" : "nie",
        [CustomAttribute.UNSUBSCRIBE_URL_RP]: unsubscribeUrlRP,
        [CustomAttribute.UNSUBSCRIBE_URL_GH]: unsubscribeUrlGH
    };

    userComUserengage(EventNames.UserRegistration, payload);
    updateUserAttributes(payload);
};
import {Attribute} from "../types/Attribute";
import {CustomAttribute} from "../types/CustomAttribute";
import {EventNames} from "../types/EventNames";
import {userComUserengage} from "../user_com_userengage";
import {updateUserAttributes} from "./update_user_attributes";

export const userRegister = (
    uuid: string,
    email: string,
    emailMarketingConsent: boolean,
    phoneMarketingConsent: boolean,
    unsubscribeUrlRP: string,
    unsubscribeUrlGH: string
) => {
    const payload = {
        event_name: EventNames.UserRegistration,
        [Attribute.USER_ID]: uuid,
        [Attribute.EMAIL]: email,
        [CustomAttribute.EMAIL_MARKETING_CONSENT]: emailMarketingConsent ? "tak" : "nie",
        [CustomAttribute.PHONE_MARKETING_CONSENT]: phoneMarketingConsent ? "tak" : "nie",
        [CustomAttribute.UNSUBSCRIBE_URL_RP]: unsubscribeUrlRP,
        [CustomAttribute.UNSUBSCRIBE_URL_GH]: unsubscribeUrlGH
    };

    userComUserengage(EventNames.UserRegistration, payload);
    updateUserAttributes(payload);
};
