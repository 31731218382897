export enum FinancingServicesCopyTypes {
    NONE_PROPERTY,
    SINGLE_PROPERTY,
    MULTI_PROPERTY,
    MODULAR_HOUSE
}
export enum FinancingServicesCopyTypes {
    NONE_PROPERTY,
    SINGLE_PROPERTY,
    MULTI_PROPERTY,
    MODULAR_HOUSE
}
