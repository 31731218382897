export enum MarketingConsentType {
    PHONE = "phone_marketing_consent",
    EMAIL = "email_marketing_consent",
    CPL = "can_receive_cpl_campaigns"
}
export enum MarketingConsentType {
    PHONE = "phone_marketing_consent",
    EMAIL = "email_marketing_consent",
    CPL = "can_receive_cpl_campaigns"
}
