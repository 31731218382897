import React from "react";
import classNames from "classnames";

import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons-module";

import {iconPosition, iconPositionMobile, iconPositionRight} from "./SliderGalleryIcon.module.css";

interface IProps {
    position: "left" | "right";
    wrapperColor: string;
    iconSize?: string;
    iconWrapperSize?: string;
    enableOnMobile?: boolean;
    fill?: string;
}
export const SliderGalleryIcon = (props: IProps) => {
    const iconProps = {
        size: props.iconSize || "2.4",
        wrapperColor: props.wrapperColor,
        wrapperSize: props.iconWrapperSize || "4.8",
        enableOnMobile: !!props.enableOnMobile,
        fill: props.fill
    };

    const iconPositionStyle = props.position === "right" && iconPositionRight;
    const mobileDisplay = props.enableOnMobile ? {display: "block"} : {display: "none"};
    const iconPositionCN = classNames(iconPosition, iconPositionStyle, mobileDisplay, iconPositionMobile);

    return (
        <div className={iconPositionCN} {...props}>
            {props.position === "left" ? <ChevronLeftIcon {...iconProps} /> : <ChevronRightIcon {...iconProps} />}
        </div>
    );
};
import React from "react";
import classNames from "classnames";

import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons-module";

import {iconPosition, iconPositionMobile, iconPositionRight} from "./SliderGalleryIcon.module.css";

interface IProps {
    position: "left" | "right";
    wrapperColor: string;
    iconSize?: string;
    iconWrapperSize?: string;
    enableOnMobile?: boolean;
    fill?: string;
}
export const SliderGalleryIcon = (props: IProps) => {
    const iconProps = {
        size: props.iconSize || "2.4",
        wrapperColor: props.wrapperColor,
        wrapperSize: props.iconWrapperSize || "4.8",
        enableOnMobile: !!props.enableOnMobile,
        fill: props.fill
    };

    const iconPositionStyle = props.position === "right" && iconPositionRight;
    const mobileDisplay = props.enableOnMobile ? {display: "block"} : {display: "none"};
    const iconPositionCN = classNames(iconPosition, iconPositionStyle, mobileDisplay, iconPositionMobile);

    return (
        <div className={iconPositionCN} {...props}>
            {props.position === "left" ? <ChevronLeftIcon {...iconProps} /> : <ChevronRightIcon {...iconProps} />}
        </div>
    );
};
