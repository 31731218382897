import {useRef, useState} from "react";
import * as Yup from "yup";

export interface IEmailVerificationProps {
    isActive: boolean;
    onClose: () => void;
}

interface EmailVerificationFunctions {
    validateEmail: (email: string) => Promise<boolean>;
    validateEmailOnce: (email: string) => Promise<boolean>;
}

export const useEmailVerification = (): {props: IEmailVerificationProps} & EmailVerificationFunctions => {
    const [shouldShowNotification, setShowNotificationStatus] = useState(false);
    const validatedEmailList = useRef<Record<string, boolean>>({});

    const validateEmail = async (email: string) => {
        closeNotification();

        const domain = email.split("@")?.[1] || "";
        const {incorrectEmailDomains} = await import("../constants/incorrect_email_domains");

        const isEmailVerified = (await Yup.string().email().isValid(email)) && !incorrectEmailDomains.includes(domain);

        if (!isEmailVerified) {
            setShowNotificationStatus(true);
        }

        return isEmailVerified;
    };

    const validateEmailOnce = async (email: string) => {
        if (validatedEmailList.current[email]) {
            return true;
        }

        validatedEmailList.current[email] = true;
        return validateEmail(email);
    };

    const closeNotification = () => {
        setShowNotificationStatus(false);
    };

    return {
        props: {
            isActive: shouldShowNotification,
            onClose: closeNotification
        },
        validateEmail,
        validateEmailOnce
    };
};
import {useRef, useState} from "react";
import * as Yup from "yup";

export interface IEmailVerificationProps {
    isActive: boolean;
    onClose: () => void;
}

interface EmailVerificationFunctions {
    validateEmail: (email: string) => Promise<boolean>;
    validateEmailOnce: (email: string) => Promise<boolean>;
}

export const useEmailVerification = (): {props: IEmailVerificationProps} & EmailVerificationFunctions => {
    const [shouldShowNotification, setShowNotificationStatus] = useState(false);
    const validatedEmailList = useRef<Record<string, boolean>>({});

    const validateEmail = async (email: string) => {
        closeNotification();

        const domain = email.split("@")?.[1] || "";
        const {incorrectEmailDomains} = await import("../constants/incorrect_email_domains");

        const isEmailVerified = (await Yup.string().email().isValid(email)) && !incorrectEmailDomains.includes(domain);

        if (!isEmailVerified) {
            setShowNotificationStatus(true);
        }

        return isEmailVerified;
    };

    const validateEmailOnce = async (email: string) => {
        if (validatedEmailList.current[email]) {
            return true;
        }

        validatedEmailList.current[email] = true;
        return validateEmail(email);
    };

    const closeNotification = () => {
        setShowNotificationStatus(false);
    };

    return {
        props: {
            isActive: shouldShowNotification,
            onClose: closeNotification
        },
        validateEmail,
        validateEmailOnce
    };
};
